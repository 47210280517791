import { getCookie, deleteCookie } from 'h3';

import { useAuthStore } from '~/stores/auth';
import { useBaseStore } from '~/stores/base';

import * as lzString from 'lz-string';

const { decompressFromEncodedURIComponent } = lzString;

export default defineNuxtRouteMiddleware(async () => {
  // skip middleware on client side entirely
  if (process.client) { return; }
  // or only skip middleware on initial client load
  const nuxtApp = useNuxtApp()
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) { return }

  const authStore = useAuthStore()
  
  const event = useRequestEvent();

  if (!event) {
    return;
  }

  const { req } = event.node;

  if (req && (req.headers['CF-IPCountry'] || req.headers['cf-ipcountry'])) {
    const country = req.headers['CF-IPCountry'] || req.headers['cf-ipcountry'];
    const b = useBaseStore();
    b.SetCountry(country);
  }

  const authCookieName = 'mp-auth';
  const token = getCookie(event, authCookieName) || null;
  if (token) {
    const a = decompressFromEncodedURIComponent(token);
    const valid = await authStore.AuthValidateCurrentSession(JSON.parse(a));
    console.log('valid', valid);
    if (!valid) {
      deleteCookie(event, authCookieName);
    }
  }
})